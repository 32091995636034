import React, { useEffect, useState, useRef } from "react";
import {
  Upload,
  Input,
  Button,
  Checkbox,
  message,
  TreeSelect,
  Tag,
  Form,
  Spin,
} from "antd";
import { useUser } from "../../context";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import { CloseOutlined } from "@ant-design/icons";
import Login from "../../components/Login";
import "./index.less";

const Publish = () => {
  const { userInfo } = useUser();

  const [form] = Form.useForm();
  const [imageList, setImageList] = useState([]);
  const [tags, setTags] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [category, setCategory] = useState([]);
  const [inputValue, setInputValue] = useState(""); // 标签输入框的值
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [coverId, setCoverId] = useState(null);

  useEffect(() => {
    getRequest("/api/category/categories").then((res) => {
      const tmpData = res.datas.filter((item) => item.type === 0);
      setCategoriesList(tmpData);
    });
  }, []);

  const base64ToBlob = (base64) => {
    const byteString = atob(base64.split(",")[1]);
    const ab = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ab[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "png" }); // 根据需要修改 MIME 类型
  };

  const convertBase64ArrayToBinary = (base64Array) => {
    const formData = new FormData();
    base64Array.forEach((base64, index) => {
      const blob = base64ToBlob(base64);
      formData.append(`images`, blob, `image${index}.png`); // key 设置为 'images[]'
    });
    return formData;
  };

  // 处理图片上传逻辑
  const handleUpload = (info) => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    const file = info.file;
    const isUnder15MB = file.size / 1024 / 1024 < 15; // 限制文件大小

    if (!isUnder15MB) {
      message.error("单个文件大小不能超过15MB");
      return; // 阻止该文件上传
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target.result;
      const newImage = {
        id: `${Date.now()}imgItems`, // 为每张图片生成唯一ID
        url: imageUrl,
      };
      setImageList((prevList) => [...prevList, newImage]);
    };
    reader.readAsDataURL(file); // 正确获取 file 对象进行读取
  };

  // 删除图片
  const handleRemove = (image) => {
    setImageList((prevList) => {
      const updatedList = prevList.filter((img) => img.id !== image.id);

      // 如果删除的是封面图片，清空封面或设置为下一张图片
      if (image.id === coverId) {
        setCoverId(updatedList.length > 0 ? updatedList[0].id : null);
      }

      return updatedList;
    });
  };

  // 处理标签的输入
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // 处理回车生成标签
  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]); // 生成新标签
      setInputValue(""); // 清空输入框
    }
  };

  // 删除标签
  const handleTagClose = (removedTag) => {
    const tmpVal = JSON.parse(JSON.stringify(tags)).filter(
      (tag) => tag.toString() !== removedTag.toString()
    );
    setTags(tmpVal);
  };

  // 提交表单
  const handleSubmit = (values) => {
    if (!userInfo?.id) {
      message.error("未登录");
      setIsLoginOpen(true);
      return;
    }

    if (imageList.length > 20) {
      message.error("最多上传20张图片");
      return; // 阻止该文件上传
    }

    if (!values.agreement) {
      // 手动设置校验错误
      form.setFields([
        {
          name: "agreement",
          errors: ["请阅读并同意用户协议"],
        },
      ]);
      return;
    }

    const formData = convertBase64ArrayToBinary(
      imageList.map((img) => img.url)
    );
    // 将其他表单字段添加到 FormData
    formData.append("categoryId", category);
    formData.append("labels", tags.join(","));
    formData.append("price", values.price * 100);
    formData.append("title", values.title);
    formData.append(
      "coverIndex",
      imageList.findIndex((image) => image.id === coverId)
    );

    setIsLoading(true);
    postRequest("/api/opus", formData, {}, 90000).then((res) => {
      setIsLoading(false);
      if (!res || res.code !== 1) {
        message.error(res?.msg || "发布失败");
      } else {
        message.success("发布成功");
        form.resetFields();
        setImageList([]);
        setTags([]);
        setCategory([]);
        setInputValue("");
      }
    });
  };

  const handleSelect = (value) => {
    setCategory(value);
  };

  const generateTreeData = (data) => {
    return data.map((category) => {
      const isParent =
        category.childCategories && category.childCategories.length > 0;
      return {
        title: category.title,
        value: category.id,
        disabled: isParent, // 禁用包含子集的父选项
        children: isParent ? generateTreeData(category.childCategories) : [],
      };
    });
  };

  const jumpToAboutUs = () => {
    window.open("/AboutUs?val=2");
  };

  // 处理拖拽事件
  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("dragIndex", index);
    // 使用当前元素作为拖拽预览
    event.dataTransfer.setDragImage(event.currentTarget, 0, 0);
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault(); // 确保不会触发默认行为
    const dragIndex = event.dataTransfer.getData("dragIndex");
    if (dragIndex !== null) {
      const updatedList = [...imageList];
      const draggedImage = updatedList.splice(dragIndex, 1)[0];
      updatedList.splice(index, 0, draggedImage);
      setImageList(updatedList);

      // 如果封面被拖动，更新封面的 ID
      if (draggedImage.id === coverId) {
        setCoverId(draggedImage.id);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // 允许放置
    event.dataTransfer.dropEffect = "move"; // 设置光标样式为可移动
  };

  const handleSetAsCover = (image) => {
    setCoverId(image.id); // 设置封面ID
    message.success("已设置为封面");
  };

  return (
    <div className="publishContainer">
      <div className="publishTopBtn">
        <div className="btnIcon" />
        <div className="btntext">发布作品赚收益</div>
      </div>
      <div className="publishMain">
        <Form
          form={form}
          onFinish={handleSubmit}
          labelCol={{ span: 4 }} // 控制 label 占的列数
          wrapperCol={{ span: 20 }}
          className="publishFormMain"
        >
          {/* 上传图片 */}
          <Form.Item
            label="选择图片"
            name="images"
            rules={[{ required: true, message: "至少选一张图片上传!" }]}
          >
            <div className="upload-container">
              {/* 上传按钮 */}
              <Upload
                listType={`${imageList.length ? "picture-card" : "picture"}`}
                className={`${imageList.length ? "" : "uploadContainer"}`}
                showUploadList={false}
                beforeUpload={handleUpload} // 手动处理上传
                onChange={handleUpload} // 处理上传变化
                multiple
                disabled={!userInfo?.id}
              >
                <div className="uploadBtn">
                  {!imageList.length ? (
                    <div className="uploadNoneBtn">
                      <div className="uploadEmptyImg" />
                      <div className="btnTitle">上传图片</div>
                      <div className="subTitle">
                        请上传JPG/JPEG/PNG格式高清渲染图片,单张大小不超过15M，最多20张。
                      </div>
                    </div>
                  ) : (
                    <div className="uploadedBtn">
                      <div className="uploadEmptyImg" />
                      <div style={{ marginTop: 24 }}>上传图片</div>
                    </div>
                  )}
                </div>
              </Upload>

              {/* 图片展示区域 */}
              {imageList.map((image, index) => (
                <div
                  key={`publish${index}`}
                  className="uploaded-image"
                  draggable
                  onDragStart={handleDragStart(index)}
                  onDrop={handleDrop(index)}
                  onDragOver={handleDragOver}
                  onDragEnter={(e) => e.preventDefault()}
                >
                  <img src={image.url} alt={`uploaded-${index}`} />

                  <div className="overlay">
                    <div
                      className="set-cover-button"
                      onClick={() => handleSetAsCover(image)}
                    >
                      {coverId === image.id ? "封面" : "设置为封面"}
                    </div>
                  </div>

                  <CloseOutlined
                    className="close-icon"
                    onClick={() => handleRemove(image)}
                  />
                </div>
              ))}
            </div>
          </Form.Item>

          {/* 作品标题 */}
          <Form.Item
            label="作品标题"
            name="title"
            rules={[{ required: true, message: "请填写作品标题" }]}
          >
            <Input placeholder="填写作品标题" />
          </Form.Item>

          {/* 分类 */}
          <Form.Item
            label="分类"
            name="category"
            rules={[{ required: true, message: "请选择分类" }]}
          >
            <TreeSelect
              treeData={generateTreeData(categoriesList)}
              value={category}
              onChange={handleSelect}
              title="请选择分类"
              placeholder="请选择分类"
              treeDefaultExpandAll={true} // 默认展开所有节点
            />
          </Form.Item>

          {/* 标签部分 */}
          <Form.Item label="标签" name="tags">
            <div>
              {tags.map((tag, index) => (
                <Tag
                  key={`tags${index}`}
                  closable
                  onClose={() => handleTagClose(tag)}
                  className="tagItem"
                >
                  {tag}
                </Tag>
              ))}
              <Input
                type="text"
                placeholder="请输入标签并按回车"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // 阻止默认提交行为
                  }
                }}
              />
              <div className="tagTip">
                提示：合适的标签将有助于该图片获得更大的曝光度和搜索命中率
              </div>
            </div>
          </Form.Item>

          {/* 定价 */}
          <Form.Item
            label="定价"
            name="price"
            rules={[{ required: true, message: "请填写定价" }]}
          >
            <div>
              <Input prefix="¥" defaultValue={0} style={{ width: "100%" }} />
              <div className="tagTip">
                提示：合理定价有助于作品更好销售，50%现金永久收益
              </div>
            </div>
          </Form.Item>

          {/* 用户协议 */}
          <Form.Item
            label=" " // 空的label，占据空间
            name="agreement"
            valuePropName="checked"
            rules={[{ required: true, message: "请同意用户协议" }]}
            className="no-asterisk" // 自定义class用于隐藏星号
          >
            <Checkbox>
              <div className="checkbox">
                <div>我已阅读并同意</div>
                <div onClick={jumpToAboutUs}>《效果图网用户协议》</div>
              </div>
            </Checkbox>
          </Form.Item>

          {/* 发布按钮 */}
          <div style={{ marginTop: "4rem", textAlign: "center" }}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#f30", // 动态设置颜色
                  borderColor: "#f30",
                  cursor: "pointer",
                }}
              >
                发布
              </Button>
            </Form.Item>
          </div>
        </Form>

        {isLoading ? (
          <div className="loading-overlay">
            <Spin size="large" />
          </div>
        ) : null}

        <Login
          isOpen={isLoginOpen}
          handleCloseModal={() => setIsLoginOpen(false)}
        />
      </div>
    </div>
  );
};

export default Publish;
