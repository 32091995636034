import React, { useEffect, useState } from "react";
import { Input, Pagination, message, Tooltip } from "antd";
import axios from "axios";
import attention from "../../assets/attention.png";
import { getRequest, postRequest } from "../../service/index"; // 引入封装的请求
import searchBlack from "../../assets/searchBlack.png";
import "./index.less";

const statusMap = {
  1: "审核中",
  2: "审核通过",
  3: "审核不通过",
  4: "已下架",
};

const Upload = () => {
  const [all, setAll] = useState(0);
  const [passing, setPassing] = useState(0);
  const [passed, setPassed] = useState(0);
  const [unpassed, setUnpassed] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [tabVal, setTabVal] = useState(0);
  const [data, setData] = useState([]);
  const [inputVal, setInputVal] = useState("");

  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    fetchData(0, tabVal);
    return () => {
      setInputVal("");
    };
  }, []);

  const fetchData = (page, status, name = "") => {
    getRequest("/api/user/published", {
      page,
      size: 5,
      status,
      name,
    }).then((res) => {
      if (res.data) {
        setAll(res.data.total);
        setData(res.data.list || []);
        setPassing(res.data.auditing);
        setPassed(res.data.audited);
        setUnpassed(res.data.failed);
        setCancel(res.data.offline);
        if (status == 0) {
          setTotal(res.data.total);
        } else if (status == 1) {
          setTotal(res.data.auditing);
        } else if (status == 2) {
          setTotal(res.data.audited);
        } else if (status == 3) {
          setTotal(res.data.failed);
        } else if (status == 4) {
          setTotal(res.data.offline);
        }
      }
    });
  };

  const onTabChange = (val) => {
    setTabVal(val);
    setPageNum(1);
    fetchData(0, val, inputVal);
  };

  const onPageChange = (page) => {
    setPageNum(page);
    fetchData(page - 1, tabVal, inputVal);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  const handleDelete = (data) => {
    postRequest("/api/opus/delete", { opusId: data.opusId }).then((res) => {
      if (!res?.code || res.code !== 1) {
        message.error(res?.msg ? res.msg : "删除失败");
        return;
      } else {
        message.success("删除成功");
        fetchData(pageNum - 1, tabVal, inputVal);
      }
    });
  };

  const handleTakeOff = (data) => {
    postRequest("/api/opus/offline", { opusId: data.opusId }).then((res) => {
      if (!res?.code || res.code !== 1) {
        message.error(res?.msg ? res.msg : "下架失败");
        return;
      } else {
        message.success("下架成功");
        fetchData(pageNum - 1, tabVal, inputVal);
      }
    });
  };

  const handleDownload = (item) => {
    const token = localStorage.getItem("token");
    axios
      .get(`https://xiaoguotu.com/api/opus/download?opusId=${item.opusId}`, {
        responseType: "blob", // 设置响应类型为blob
        headers: {
          Authorization: `${token}`, // 添加Authorization头
          Accept: "application/zip", // 指定下载类型为ZIP
        },
      })
      .then((response) => {
        // 创建一个URL对象
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

        // 创建一个链接元素
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", response.headers["x-filename"]); // 设置下载文件名

        // 将链接添加到DOM并点击
        document.body.appendChild(link);
        link.click();

        // 移除链接元素
        document.body.removeChild(link);
      });
  };

  const checkDetail = (data) => {
    if (+data.status === 2) {
      window.open(`/detail?opusId=${data.opusId}`);
    }
  };

  const handleSearch = () => {
    fetchData(pageNum - 1, tabVal, inputVal);
  };

  return (
    <div className="upLoadMain">
      <div className="tabMain">
        <div className="tabMainLeft">
          <div
            className={`${tabVal === 0 ? "active" : ""} tabItem`}
            onClick={() => onTabChange(0)}
          >
            全部({all})
          </div>
          <div
            className={`${tabVal === 1 ? "active" : ""} tabItem`}
            onClick={() => onTabChange(1)}
          >
            审核中({passing})
          </div>
          <div
            className={`${tabVal === 2 ? "active" : ""} tabItem`}
            onClick={() => onTabChange(2)}
          >
            审核通过({passed})
          </div>
          <div
            className={`${tabVal === 3 ? "active" : ""} tabItem`}
            onClick={() => onTabChange(3)}
          >
            审核不通过({unpassed})
          </div>
          <div
            className={`${tabVal === 4 ? "active" : ""} tabItem`}
            onClick={() => onTabChange(4)}
          >
            已下架({cancel})
          </div>
        </div>

        <div className="searchComp">
          <Input
            className="searchCompInput"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
            onPressEnter={handleSearch}
            placeholder="请输入关键词或id搜索"
          />
          <img
            onClick={handleSearch}
            className="searchCompBtn"
            src={searchBlack}
          />
        </div>
      </div>

      {data.length ? (
        <div className="dataContainer">
          {data.map((item, index) => {
            return (
              <div
                className="dataItem"
                key={`upLoad${index}${index}`}
                onClick={() => checkDetail(item)}
              >
                <div className="itemLeft">
                  <img className="itemPic" src={item.cover} />
                  <div className="itemMessage">
                    <div className="itemMessageTop">
                      <div className="itemTitle">{item.title}</div>

                      {+item.status === 2 ? (
                        <div className="itemSubTitle">
                          <div className="itemSubTitleRow">
                            <div className="itemMessageTopMiddleItem">
                              展示次数：{item.viewCnt}
                            </div>
                            <div className="itemMessageTopMiddleItem">
                              收藏量：{item.collectCnt}
                            </div>
                            <div className="itemMessageTopMiddleItem">
                              收益笔数：{item.incomeCnt}
                            </div>
                          </div>
                          <div className="itemSubTitleRow">
                            <div className="itemMessageTopMiddleItem">
                              单价：{item.price / 100}元
                            </div>
                            <div className="itemMessageTopMiddleItem">
                              累计收益：{item.income}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="itemPrice">
                          单价：{item.price / 100}元
                        </div>
                      )}
                      <div className="itemTime">{item.publishTime}</div>
                    </div>
                    <div className="itemMessageBottom">
                      <div className={`itemStatus status${item.status}`}>
                        {statusMap[item.status]}
                      </div>
                      {item.status == 3 ? (
                        <Tooltip
                          title={
                            <div>
                              未通过原因：
                              <br />
                              <br />
                              {item.message}
                            </div>
                          }
                          placement="top"
                        >
                          <div className="tips">
                            <img className="tipsIcon" src={attention} />

                            <div>查看未通过原因</div>
                          </div>
                        </Tooltip>
                      ) : null}
                    </div>
                  </div>
                </div>
                {item.status == 3 ? (
                  <div className="itemRight">
                    <div
                      className="itemRightBtn"
                      onClick={() => handleDelete(item)}
                    >
                      删除
                    </div>
                  </div>
                ) : null}
                {item.status == 2 ? (
                  <div className="itemRight">
                    <div
                      className="itemRightBtnRed"
                      onClick={() => handleDownload(item)}
                    >
                      下载
                    </div>
                    <div className="itemRightBtnSplitLine" />
                    <div
                      className="itemRightBtn"
                      onClick={() => handleTakeOff(item)}
                    >
                      下架
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}

      <Pagination
        className="paginationContainer"
        total={total}
        current={pageNum}
        pageSize={5}
        itemRender={itemRender}
        showSizeChanger={false}
        onChange={onPageChange}
      />
    </div>
  );
};

export default Upload;
