import React, { useEffect, useState, useRef } from "react";
import { Table, Pagination, Button, Image, message, Modal, Input } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./index.less";
let token = localStorage.getItem("adminToken");

const getStatusText = (status) => {
  switch (status) {
    case 1:
      return { text: "审核中", color: "#d39e00" };
    case 2:
      return { text: "审核通过", color: "#28a745" };
    case 3:
      return { text: "审核不通过", color: "#f30" };
    case 4:
      return { text: "已下架", color: "#999" };
    default:
      return { text: "未知状态", color: "#000" };
  }
};

const Examine = (props) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]); // 存储图片数据
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [isOpen, setIsopen] = useState(false);
  const [messageData, setMessageData] = useState("");
  const approvalData = useRef({});

  useEffect(() => {
    token = localStorage.getItem("adminToken");
    if (props.selected === 2) {
      fetchImages();
    }
  }, [props.selected]);

  const fetchImages = () => {
    axios
      .get("https://xiaoguotu.com/api/admin/audit_opus", {
        params: { page: pageNum - 1, size: 5 },
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        setTotal(res.data.data.total);
        setImages(res.data.data.opusVOs || []);
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const handleApprove = (data, status) => {
    axios
      .post(
        "https://xiaoguotu.com/api/admin/audit_opus",
        {
          opusId: data.opusId,
          message: messageData,
          status,
        },
        {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        }
      )
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "操作失败");
          return;
        } else {
          message.success("操作成功");
          approvalData.current = {};
          setMessageData("");
          fetchImages();
          setIsopen(false);
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const handleDisApproval = () => {
    handleApprove(approvalData.current, 3);
  };

  const handleOpenModal = (data) => {
    approvalData.current = data;
    setIsopen(true);
  };

  const handleCloseModal = () => {
    approvalData.current = {};
    setMessageData("");
    setIsopen(false);
  };

  const columns = [
    {
      title: "封面",
      dataIndex: "cover",
      render: (data, record) => {
        return (
          <Image.PreviewGroup items={record.images}>
            <Image width={150} height={100} src={data} />
          </Image.PreviewGroup>
        );
      },
    },
    {
      title: "标题",
      dataIndex: "title",
    },
    {
      title: "审核状态",
      dataIndex: "status",
      render: (text) => {
        const { text: statusText, color } = getStatusText(text);
        return <span style={{ color }}>{statusText}</span>;
      },
    },
    {
      title: "价格",
      dataIndex: "price",
      render: (text) => {
        return <span>{text / 100} 元</span>;
      },
    },
    {
      title: "审核操作",
      dataIndex: "action",
      render: (_, record) => (
        <div>
          <Button
            type="primary"
            onClick={() => handleApprove(record, 2)}
            style={{ marginRight: 8 }}
          >
            通过
          </Button>
          <Button type="default" onClick={() => handleOpenModal(record)}>
            不通过
          </Button>
        </div>
      ),
    },
  ];

  const onPageChange = (page) => {
    setPageNum(page);
    fetchImages(page - 1);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>上一页</a>;
    }
    if (type === "next") {
      return <a>下一页</a>;
    }
    return originalElement;
  };

  return (
    <div className="examineMain">
      <div className="examineTitle">作品审核</div>

      <Table
        columns={columns}
        dataSource={images}
        pagination={false}
        locale={{ emptyText: "暂无数据" }}
      />

      <Pagination
        className="paginationContainer"
        total={total}
        pageSize={5}
        current={pageNum}
        itemRender={itemRender}
        showSizeChanger={false}
        onChange={onPageChange}
      />

      <Modal
        title="不通过原因"
        open={isOpen}
        centered
        onCancel={handleCloseModal}
        footer={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              onClick={handleDisApproval}
              className="modalBtn"
            >
              确定
            </Button>
          </div>
        }
      >
        <Input
          value={messageData}
          className="modalInput"
          onChange={(e) => setMessageData(e.target.value)}
          placeholder="请输入不通过原因"
        />
      </Modal>
    </div>
  );
};

export default Examine;
