import React, { useEffect, useState } from "react";
import { Upload, Input, Button, message, Space } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DeleteOutlined,
  RightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./index.less";
let token = localStorage.getItem("adminToken");

const CategoriesConfig = (props) => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(0);

  const [categoriesData, setCategoriesData] = useState([]);
  // const [subCategoriesData, setSubCategoriesData] = useState([]);

  useEffect(() => {
    token = localStorage.getItem("adminToken");

    if (props.selected === 1) {
      getCategories(true);
    }
  }, [props.selected]);

  const getCategories = (isInit) => {
    axios
      .get("https://xiaoguotu.com/api/admin/categories", {
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        const data = res.data.datas.filter((item) => item.type == 0);
        setCategoriesData(data);
        if (isInit) {
          // setSubCategoriesData(data[0]?.childCategories || []);
          setSelectedId(data[0].id);
          // } else {
          //   res.data.datas.forEach((item) => {
          //     if (item.id === selectedId) {
          //       setSubCategoriesData(item?.childCategories || []);
          //     }
          //   });
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  // const handleIconChange = ({ file, onSuccess, onError }, item) => {
  //   if (!file) {
  //     message.error("请先选择图片");
  //     return;
  //   }
  //   const formData = new FormData();
  //   formData.append("image", file); // 将文件添加到 FormData
  //   axios
  //     .post("https://xiaoguotu.com/api/oss/upload_image", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: token,
  //       },
  //     })
  //     .then((response) => {
  //       if (
  //         !response?.data?.code ||
  //         response?.data.code !== 1 ||
  //         !response?.data?.data
  //       ) {
  //         message.error(response?.data?.msg ? response.data.msg : "上传失败");
  //         return;
  //       } else {
  //         axios
  //           .post(
  //             "https://xiaoguotu.com/api/admin/modifyCategory",
  //             {
  //               icon: response.data.data,
  //               id: item.id,
  //               parentId: item.parentId,
  //               title: item.title,
  //             },
  //             {
  //               headers: {
  //                 Authorization: token, // 设置 Authorization 头部
  //               },
  //             }
  //           )
  //           .then((res) => {
  //             if (!res?.data?.code || res?.data.code !== 1) {
  //               message.error(res?.data?.msg ? res?.data.msg : "更新失败");
  //               return;
  //             } else {
  //               message.success("更新成功");
  //               const tmpData = JSON.parse(JSON.stringify(categoriesData));
  //               tmpData.forEach((tmpItem) => {
  //                 if (tmpItem.id === item.id) {
  //                   tmpItem.icon = response.data.data;
  //                 }
  //               });
  //               setCategoriesData(tmpData);
  //             }
  //           })
  //           .catch((error) => {
  //             if (+error.response.status === 401) {
  //               message.error("用户登录失效，请重新登录");
  //               navigate(`/secure/6/login`);
  //             }
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       if (+error.response.status === 401) {
  //         message.error("用户登录失效，请重新登录");
  //         navigate(`/secure/6/login`);
  //       }
  //     });
  // };

  const handleChangeTitle = (e, data, isParent) => {
    let tmpData;
    if (isParent) {
      tmpData = JSON.parse(JSON.stringify(categoriesData));
      // } else {
      // tmpData = JSON.parse(JSON.stringify(subCategoriesData));
    }
    tmpData.forEach((tmpItem) => {
      if (tmpItem.id === data.id) {
        tmpItem.title = e.target.value;
      }
    });
    if (isParent) {
      setCategoriesData(tmpData);
      // } else {
      // setSubCategoriesData(tmpData);
    }
  };

  const handleSubmitTitle = (data, isParent) => {
    let tmpData;
    if (isParent) {
      tmpData = JSON.parse(JSON.stringify(categoriesData));
      // } else {
      // tmpData = JSON.parse(JSON.stringify(subCategoriesData));
    }
    tmpData.forEach((tmpItem) => {
      if (tmpItem.id === data.id) {
        axios
          .post(
            "https://xiaoguotu.com/api/admin/modifyCategory",
            {
              // icon: tmpItem.data,
              id: tmpItem.id,
              parentId: tmpItem?.parentId,
              title: tmpItem.title,
            },
            {
              headers: {
                Authorization: token, // 设置 Authorization 头部
              },
            }
          )
          .then((res) => {
            if (!res?.data?.code || res?.data.code !== 1) {
              message.error(res?.data?.msg ? res?.data.msg : "修改失败");
              return;
            } else {
              message.success("修改成功");
            }
          })
          .catch((error) => {
            if (+error.response.status === 401) {
              message.error("用户登录失效，请重新登录");
              navigate(`/secure/6/login`);
            }
          });
      }
    });
  };

  // const checkRightItem = (data) => {
  //   setSelectedId(data.id);
  //   setSubCategoriesData(data?.childCategories || []);
  // };

  const deleteItem = (data) => {
    axios
      .post(
        "https://xiaoguotu.com/api/admin/removeCategory",
        { ids: [data.id] },
        {
          headers: {
            Authorization: token, // 设置 Authorization 头部
          },
        }
      )
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "删除失败");
        } else {
          message.success("删除成功");
          setTimeout(() => {
            getCategories();
          }, 100);
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const onMove = (data, direction, isParent) => {
    let tmpData;
    if (isParent) {
      tmpData = JSON.parse(JSON.stringify(categoriesData));
      // } else {
      // tmpData = JSON.parse(JSON.stringify(subCategoriesData));
    }

    const index = tmpData.findIndex((item) => item.id === data.id);
    // 如果未找到，返回原数组
    if (index === -1) return;
    const newIndex = direction === "up" ? index - 1 : index + 1;

    if (newIndex < 0 || newIndex >= tmpData.length) return;

    const newArray = JSON.parse(JSON.stringify(tmpData));

    // 交换元素
    [newArray[index], newArray[newIndex]] = [
      newArray[newIndex],
      newArray[index],
    ];

    const param = {
      id: newArray.map((item) => item.id),
    };

    axios
      .post("https://xiaoguotu.com/api/admin/orderCategory", param, {
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "位置调整失败");
        } else {
          message.success("位置调整成功");
          if (isParent) {
            setCategoriesData(newArray);
            // } else {
            // setSubCategoriesData(newArray);
          }
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  const handleAddItem = (isParent) => {
    let tmpData;
    if (isParent) {
      tmpData = JSON.parse(JSON.stringify(categoriesData));
      // } else {
      // tmpData = JSON.parse(JSON.stringify(subCategoriesData));
    }
    const param = {
      childCategories: [],
      icon: "",
      order: isParent ? tmpData.length + 1 : tmpData.length - 1, // 由于一级分类过滤掉了前两个分类因此order为了设置为最后一个需要lenth-1+2
      parentId: isParent ? 0 : selectedId,
      title: "默认分类",
      type: 0,
    };
    axios
      .post("https://xiaoguotu.com/api/admin/addCategory", param, {
        headers: {
          Authorization: token, // 设置 Authorization 头部
        },
      })
      .then((res) => {
        if (!res?.data?.code || res?.data.code !== 1) {
          message.error(res?.data?.msg ? res?.data.msg : "新增失败");
        } else {
          message.success("新增成功");
          setTimeout(() => {
            getCategories();
          }, 100);
        }
      })
      .catch((error) => {
        if (+error.response.status === 401) {
          message.error("用户登录失效，请重新登录");
          navigate(`/secure/6/login`);
        }
      });
  };

  return (
    <div className="tabConfig">
      <div className="tabConfigTitle">分类标签配置</div>

      <div className="tabConfigMain">
        <div className="tabConfigMainLeft">
          {categoriesData.map((item, index) => {
            return (
              <div className="configItem" key={`categoty${item.id}`}>
                {/* <div className="configLabel">图标：</div>
                <Upload
                  showUploadList={false}
                  customRequest={(_) => handleIconChange(_, item)}
                  maxCount={1}
                >
                  <img src={item.icon} className="configIcon" />
                </Upload> */}

                <div className="configLabel">分类名称：</div>
                <Space
                  direction="vertical"
                  size="middle"
                  className="configInputContainer"
                >
                  <Space.Compact className="configInputItemContainer">
                    <Input
                      className="configInput"
                      defaultValue={item.title}
                      onChange={(e) => handleChangeTitle(e, item, true)}
                    />
                    <Button
                      className="configInputBtn"
                      type="primary"
                      onClick={() => handleSubmitTitle(item, true)}
                    >
                      确认
                    </Button>
                  </Space.Compact>
                </Space>

                <Button
                  disabled={index === 0}
                  className="configIconBtn"
                  icon={<ArrowUpOutlined />}
                  onClick={() => onMove(item, "up", true)}
                />
                <Button
                  disabled={index === categoriesData.length - 1}
                  className="configIconBtn"
                  icon={<ArrowDownOutlined />}
                  onClick={() => onMove(item, "down", true)}
                />
                <Button
                  className="configIconBtn"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteItem(item)}
                />
                {/* <Button
                  className={`${
                    selectedId == item.id ? "selectedBtn" : ""
                  } configIconBtn`}
                  icon={<RightOutlined />}
                  onClick={() => checkRightItem(item)}
                /> */}
              </div>
            );
          })}
          <Button
            className="configAddBtn"
            icon={<PlusOutlined />}
            onClick={() => handleAddItem(true)}
          />
        </div>
        {/* <div className="tabConfigSplitLine" /> */}

        {/* <div className="tabConfigMainRight">
          {subCategoriesData.map((item, index) => {
            return (
              <div className="subConfigItem" key={`name${item.id}`}>
                <div className="subConfigLabel">分类名称：</div>
                <Space
                  direction="vertical"
                  size="middle"
                  className="subConfigInputContainer"
                >
                  <Space.Compact className="subConfigInputItemContainer">
                    <Input
                      className="subConfigInput"
                      defaultValue={item.title}
                      onChange={(e) => handleChangeTitle(e, item, false)}
                    />
                    <Button
                      className="subConfigInputBtn"
                      type="primary"
                      onClick={() => handleSubmitTitle(item, false)}
                    >
                      确认
                    </Button>
                  </Space.Compact>
                </Space>

                <Button
                  disabled={index === 0}
                  className="subConfigIconBtn"
                  icon={<ArrowUpOutlined />}
                  onClick={() => onMove(item, "up", false)}
                />
                <Button
                  disabled={index === subCategoriesData.length - 1}
                  className="subConfigIconBtn"
                  icon={<ArrowDownOutlined />}
                  onClick={() => onMove(item, "down", false)}
                />
                <Button
                  className="subConfigIconBtn"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteItem(item)}
                />
              </div>
            );
          })}
          <Button
            className="configAddBtn"
            icon={<PlusOutlined />}
            onClick={() => handleAddItem(false)}
          />
        </div> */}
      </div>
    </div>
  );
};

export default CategoriesConfig;
